import communLangage from "@/commun/commun.langage.js";
import axios from "axios";

export default {
     name: "Header",

     components: {
     },

     data() {
          return {
               estConnecte: false,
               menu: false,
               entreeMenu: [],
               nav:{}
          }
     },

     computed: {
          langage() {
               console.log(communLangage.langage)
               return communLangage.langage;
          },

          route(){
               return this.$route.path.split('/')[1];
          }
     },

     methods: {
          deconnecter() {
               this.$store.dispatch("deconnecterUtilisateur");
               this.$router.push('/connexion');
          },

          afficherProfil() {

          },

          changerLangage(langage) {
               this.$store.dispatch('changerLangage', {
                    langage: langage
               })
               communLangage.recupererLangage();

          },

          recupererTextes() {
               axios.get(process.env.BASE_URL + 'assets/textes/header/menu.json').then(result => {
                    this.entreeMenu = this.langage == "fr" ? result.data.fr : result.data.en;
               });

               axios.get(process.env.BASE_URL + 'assets/textes/header/nav.json').then(result => {
                    this.nav = this.langage == "fr" ? result.data.fr : result.data.en;
               });
          }

     },

     watch: {
          langage: function () {
               this.recupererTextes();
          }
     },


     created() {
          this.estConnecte = this.$store.getters.estAuthentifie;

          communLangage.recupererLangage();
          this.recupererTextes();
          console.log(this.$route)
     },

}