import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';



import { createApp } from 'vue'

import App from './App.vue'
import communGeneral from './commun/commun.general';
import router from './router'

import store from "./store/store.js"

createApp(App)
  .directive('scroll', function (el) {
    var oldScrollY = 0;
    let f = function () {
      if (window.scrollY > 25) {
        if (window.scrollY > oldScrollY) {
          oldScrollY = window.scrollY;
        }
        el.setAttribute(
          'style',
          'transform: translate3d(0, 0px, 0);top:-40px'
        )
      }
      if (window.scrollY > 300) {
        if (window.scrollY > oldScrollY) {
          oldScrollY = window.scrollY;
        }
        el.setAttribute(
          'style',
          'opacity: 0; transform: translate3d(0, 0px, 0);top:-40px;visibility:hidden'
        )
      }
      if (window.scrollY < oldScrollY && communGeneral.vscroll) {
        oldScrollY = window.scrollY;
        el.setAttribute(
          'style',
          'opacity: 1; transform: translate3d(0, 0px, 0);top:-40px;visibility:visible'
        )
      }
      if (window.scrollY < 25) {
        el.setAttribute(
          'style',
          'opacity: 1; transform: translate3d(0, 0px, 0);visibility:visible'
        )
      }
    }
    window.addEventListener('scroll', f)

  })
  .directive('scroll-nav-espace-client', function (el) {
    var oldScrollY = 0;
    let f = function () {
      if (window.scrollY > 25) {
        if (window.scrollY > oldScrollY) {
          oldScrollY = window.scrollY;
        }
        el.setAttribute(
          'style',
          'display: none'
        )
      }
      if (window.scrollY < 25) {
        el.setAttribute(
          'style',
          'display: flex'
        )
      }
    }
    window.addEventListener('scroll', f)

  })
  .directive('scroll-btn-top', function (el) {
    var oldScrollY = 0;
    let f = function () {
      if (window.scrollY >= 50) {
        if (window.scrollY > oldScrollY) {
          oldScrollY = window.scrollY;
        }
        el.setAttribute(
          'style',
          'display: flex;position:sticky;left:100%;bottom:2%'
        )
      }
      if (window.scrollY < 50) {
        el.setAttribute(
          'style',
          'display: none'
        )
      }
    }
    window.addEventListener('scroll', f)

  })
  .directive('click-btn-href', function () {
    let f = function () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      
    }
    window.addEventListener('click', f)

  }).use(router).use(store).mount('#app')


