export default {
    langage: "fr",

    recupererLangage() {
        this.langage = localStorage.getItem('langage');
    }
}



