<template>
<div class="background-color-body w-100" style="height: var(--height)" id="app">
  <div style="height: var(--header-height)" class="w-100">
    <Header class="h-100"/>
  </div>
  <router-view style="height: var(--body-height)" class="w-100" />
</div>
</template>

<script src="./App.js"></script>

<style src="@/assets/style.css" ></style>

<style>
:root{
    --height: 100vh;
    --header-height: 120px;
    --nav-espace-client-height: 40px;
    --nav-height: 80px;
    --body-height: calc(var(--height) - var(--header-height) - 0px);
    --titre-height: 120px;
    --infos-height: calc(var(--body-height) - var(--titre-height) - 120px );
}

hr{
  z-index: 1002;
}
</style>




